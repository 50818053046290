document.addEventListener("turbolinks:load", function () {
  if (window.location.href == "https://www.joaodebarrochapada.com.br/" 
  || window.location.href == "https://www.joaodebarrochapada.com.br/#about" 
  || window.location.href == "https://www.joaodebarrochapada.com.br/#products" 
  || window.location.href == "https://www.joaodebarrochapada.com.br/#contact_info" 
  || window.location.href == "https://www.joaodebarrochapada.com.br/#feedback"
  || window.location.href == "https://localhost:3000/" 
  || window.location.href == "https://localhost:3000/#about" 
  || window.location.href == "https://localhost:3000/#products" 
  || window.location.href == "https://localhost:3000/#contact_info" 
  || window.location.href == "https://localhost:3000/#feedback") {

    var sliders = document.querySelectorAll("#sliders div");
    var current = 0;
    var index = 0;
    var total = sliders.length - 1;

    var anterior = document.querySelector("a#anterior");
    //console.log(anterior);
    var proximo = document.querySelector("a#proximo");
    //console.log(proximo);

    // Lógica do slider
    function mySlider() {
      // Se o usuário clicar no botão do previous e dps clicar no next, o index estará maior que o current, logo é necessário arrumar
      if (index > current) {
        if (index == total) {
          index = 0;
          current = current + 1;
        } else {
          current = index;
          index = index - 1;
        }
        sliders[current].className = "slider-active";
        sliders[index].className = "slider";
      } else {
        // Se o usuário clicar no next antes do carousel iniciar, ou seja, se current == index == 0
        if (current == index) {
          current = current + 1;
          sliders[current].className = "slider-active";
          sliders[index].className = "slider";
        } else {
          // Se o slide ativo for o último e o index for o primeiro, qnd clicar pro próximo o ativo vai ter q ser o primeiro
          // e o index vai ter q ser o ultimo (para que o atributo active seja retirado)
          if (current == total && index == 0) {
            current = 0;
            sliders[current].className = "slider-active";
            index = total;
            sliders[index].className = "slider";
          } else {
            current = current >= total ? 0 : current + 1;
            sliders[current].className = "slider-active";
            index = index >= total ? 0 : index + 1;
            sliders[index].className = "slider";
          }
        }
      }
      
      // console.log("2º Instante:");
      // console.log(`Current: ${current}`);
      // console.log(`index: ${index}`);
    }

    var carouselAut = setInterval(function () {
      mySlider();
    }, 4000);

    // Resetar o tempo de contagem do carousel
    function pause() {
      clearInterval(carouselAut);
    }
    // Retomar a execução do carousel
    function resume() {
      clearInterval(carouselAut);
      carouselAut = setInterval(function () {
        mySlider();
      }, 4000);
    }

    //Parar carousel quando o mouse passar em cima das setinhas de next/previous
    anterior.addEventListener("mouseenter", pause);
    proximo.addEventListener("mouseenter", pause);
    
    // Retomar carousel quando o mouse sair de cima das setinhas de next/previous    
    anterior.addEventListener("mouseleave", resume);
    proximo.addEventListener("mouseleave", resume);

    proximo.addEventListener("click", function () {
        mySlider();
    });

    anterior.addEventListener("click", function () {
      index = current;
      current = current == 0 ? total : current - 1;
      sliders[current].className = "slider-active";
      sliders[index].className = "slider";

      //console.log("Clicou no Anterior:");
      //console.log(`Current: ${current}`);
      //console.log(`index: ${index}`);
    });
  }

});
