document.addEventListener("turbolinks:load", function () {
  if (window.location.href == "https://www.joaodebarrochapada.com.br/" 
  || window.location.href == "https://www.joaodebarrochapada.com.br/#about" 
  || window.location.href == "https://www.joaodebarrochapada.com.br/#products" 
  || window.location.href == "https://www.joaodebarrochapada.com.br/#contact_info" 
  || window.location.href == "https://www.joaodebarrochapada.com.br/#feedback") {
    var feedsliders = document.querySelectorAll("#feedback_sliders li");
    var f_current = 0;
    var f_total = feedsliders.length - 1;
    //console.log(f_total);

    var f_anterior = document.querySelector("a#feed_anterior");
    //console.log(f_anterior);
    var f_proximo = document.querySelector("a#feed_proximo");
    //console.log(f_proximo);

    var f_index = 0;

    f_proximo.addEventListener("click", function () {
      feedsliders[f_index].className = "feed_slider";
      f_current = f_current >= f_total ? 0 : f_current + 1;
      feedsliders[f_current].className = "feed_slider-active";
      f_index = f_index >= f_total ? 0 : f_index + 1;
    });

    f_anterior.addEventListener("click", function () {
      feedsliders[f_index].className = "feed_slider";
      f_current = f_current <= 0 ? f_total : f_current - 1;
      feedsliders[f_current].className = "feed_slider-active";
      f_index = f_index <= 0 ? f_total : f_index - 1;
    });
  }
});
