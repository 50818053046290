const { default: Swal } = require("sweetalert2");

document.addEventListener("turbolinks:load", function () {
  if (window.location.href == "https://www.joaodebarrochapada.com.br/" 
  || window.location.href == "https://www.joaodebarrochapada.com.br/#about" 
  || window.location.href == "https://www.joaodebarrochapada.com.br/#products" 
  || window.location.href == "https://www.joaodebarrochapada.com.br/#contact_info" 
  || window.location.href == "https://localhost:3000/"
  || window.location.href == "https://localhost:3000/#about"
  || window.location.href == "https://localhost:3000/#products"
  || window.location.href == "https://localhost:3000/#contact_info"){
    var tpo_acomodacao = document.getElementById("contact_info_option");
    var qtd_criancas = document.getElementById("contact_info_qnt_child");
    var botao_contact = document.getElementById("submitContact");
    

    // Aviso sobre quantidade de pessoas permitida no container
    tpo_acomodacao.addEventListener("change", function () {
      if (tpo_acomodacao.value == 1) {
        Swal.fire({
          icon: "warning",
          text: "A suíte container comporta apenas duas pessoas! Se for o caso, opte pelo camping!",
          confirmButtonText: 'OK',
          confirmButtonColor: '#28a745',
          confirmButtonAriaLabel: '#28a745'
          });
      }
    });

    // Consertar lógica dessa budega
    // botao_contact.addEventListener("click", function (e) {
    //   e.preventDefault();
    //   var form = $("#formzada")
    //   Swal.fire({
    //     icon: 'success',
    //     text:"Obrigado pela preferência. Você receberá um e-mail com as instruções para finalizar a sua hospedagem. Lembre-se de verificar na caixa de spam/promoções."
    //   }).then((result) => {
    //     if (result.value){
    //       $('#formzada').closest('form').submit();
    //     } else {
    //       Swal.fire({
    //         icon: 'warning',
    //         text: "Preencha o formulário corretamente.",
    //         cancelButtonText: "Voltar e preencher corretamente."
    //       }).then((result) => {
    //         if (result.isDismissed){
    //            Swal.close();
    //         }
    //       });
    //     }
    //   });
    // });
    
  }

  if (window.location.href == "https://www.joaodebarrochapada.com.br/feedbacks/new"){
    var btn_enviar = document.querySelector("div#feedb_submit")
    
    btn_enviar.addEventListener("click", function(e){
      
      Swal.fire({
        text: "Obrigado pelo feedback! Volte sempre! :)",
        icon: "success"});
    });
  }
  var close_alerta = document.getElementById("close_button")
  
  if (close_alerta){
    close_alerta.addEventListener("click", function(){
      var alerta_erro = document.getElementById("alertas_gerais")
      alerta_erro.style.display = "none";
    });
  }
  


  // Fazendo uma validação para só os proprietários do site poderem registrar um admin
  // if (window.location.href == "https://localhost:3000/joaodebarro/admin/registrar" || window.location.href == "http://www.joaodebarrochapada.com.br/joaodebarro/admin/registrar" ){
  //   var teste = prompt("Por favor, digite a senha de verificação.", "senha")
  //   if (teste !== "123456"){
  //     document.location.href = "/"
  //   }
  // }

});
